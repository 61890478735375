export interface ICookieModuleProps { }
export interface ICookieModuleObject {
    createCookieToken: (key: string, domain: string, token: string) => void
}

export type TUseCookieModule = (props: ICookieModuleProps) => ICookieModuleObject


export const useCookieModule: TUseCookieModule = () => {

    const createCookieToken: ICookieModuleObject['createCookieToken'] = (key, domain, token) => {
        document.cookie = `${key}=${token};domain=${domain};path=/`
    }

    return { createCookieToken }
}