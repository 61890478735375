import react from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { All, Loader } from './elements/all.elements';
import { AuthPage } from './pages/Auth.page';
import { useStore } from './store';

const App: React.FC = () => {

  const store = useStore({})
  
  return (
    <All>
      <Loader isOpen={store.app.isLoading}/>
      <BrowserRouter>
        <Switch>
          <Route path='/' exact>
            <AuthPage store={store}/>
          </Route>
          <Redirect to='/'></Redirect>
        </Switch>
      </BrowserRouter>
    </All>
  );
};

export default App;
