import { useState } from "react"
export interface IAppModuleProps {}
export interface IAppModuleObject {
    isLoading: boolean
    showLoader: () => void
    hideLoader: () => void
    onRedirectTo: (url: string) => void
}

export type TUseAppModule = (props: IAppModuleProps) => IAppModuleObject

export const useAppModule: TUseAppModule = () => {

    const [isLoading, setIsLoading] = useState<IAppModuleObject['isLoading']>(false)
    
    const showLoader: IAppModuleObject['showLoader'] = () => setIsLoading(true)
    const hideLoader: IAppModuleObject['hideLoader'] = () => setIsLoading(false)
    const onRedirectTo: IAppModuleObject['onRedirectTo'] = url=> window.location.replace(url);
    // window.location.href = "http://www.w3schools.com";

    return {
        isLoading,
        showLoader,
        hideLoader,
        onRedirectTo
    }
}