import { ISetup } from "../models/setup.model"
import axios from 'axios'


export interface ISetupServiceProps {}

export interface ISetupServiceObject {
    getConfig: () => Promise<ISetup>
}

export type IUseService = (props: ISetupServiceProps) => ISetupServiceObject

export const useSetupService: IUseService = () => {

    const getConfig = async () => {
        const response = await axios.get<ISetup>('/runtime-config.json')
        return response.data
    }
    
    return {
        getConfig
    }
}