import styled from "styled-components";

export const BackgroundImage = styled.div<{src: string}>`
    height: 100vh;
    display: block;
    width: 100%;
    background-image: ${props=> `url(${props.src})`};
    background-repeat: no-repeat;
    background-position: center center;
    background-size:cover;
`