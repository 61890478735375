import { useState } from 'react'
import { ISetup } from '../models/setup.model'
import { useAuthService } from '../services/auth.service'
import { IAppModuleObject } from './app.module'
import { ICookieModuleObject } from './cookie.module'
import { ISetupModuleObject } from './setup.module'

export interface ISignInModuleProps {
    app: IAppModuleObject,
    cookie: ICookieModuleObject,
    setup: ISetupModuleObject
}
export interface IAuthModuleObject {

    isEmailRegistered: boolean
    isNewAccount: boolean
    isIncorrectPassword: boolean
    isCreatedAccount: boolean
    isRecoveredAccount: boolean
    isEmailValid: boolean
    isValidatePassword: boolean

    emailInputValue: string
    passwordInputValue: string
    
    fullName: string
    
    onGoBack: () => void
    onChangeEmailInput: (email: string) => void
    onKeyDownEmailInput: (key: string) => void
    onKeyDownPasswordInput: (key: string) => void
    onChangePasswordInput: (password: string) => void
    onSubmitCheckEmail: () => Promise<void>
    onSubmitGetInto: () => Promise<void>
    onSubmitSignUp: () => Promise<void>
    setSetup: (setup: ISetup) => void
    checkEmail: (email: string) => Promise<boolean>
    onRecoveryPassword: () => Promise<void>
}

export type TUseSignInHook = (props: ISignInModuleProps) => IAuthModuleObject

export const useAuthModule: TUseSignInHook = (props) => {

    const authService = useAuthService({})
    const [isEmailValid, setIsEmailValid] = useState<IAuthModuleObject['isEmailValid']>(false)

    const [isIncorrectPassword, setIsIncorrectPassword] = useState<IAuthModuleObject['isIncorrectPassword']>(false)

    const [isEmailRegistered, setIsEmailRegistered] = useState<IAuthModuleObject['isEmailRegistered']>(false)
    const [isNewAccount, setIsNewAccount] = useState<IAuthModuleObject['isNewAccount']>(false)
    const [isCreatedAccount, setIsCreatedAccount] = useState<IAuthModuleObject['isCreatedAccount']>(false)
    const [emailInputValue, setEmailInputValue] = useState<IAuthModuleObject['emailInputValue']>('')
    const [passwordInputValue, setPasswordInputValue] = useState<IAuthModuleObject['emailInputValue']>('')
    const [fullName, setFullName] = useState<string>('')
    const [isRecoveredAccount, setIsRecoveredAccount] = useState<IAuthModuleObject['isRecoveredAccount']>(false)

    const onGoBack = () => {
        setIsEmailRegistered(false)
        setIsIncorrectPassword(false)
        setIsNewAccount(false)
        setEmailInputValue('')
        setPasswordInputValue('')
        setIsCreatedAccount(false)
        setIsRecoveredAccount(false)
        setIsEmailValid(false)
    }

    const onChangeEmailInput: IAuthModuleObject['onChangeEmailInput'] = email => {
        setEmailInputValue(email)
        validateEmail(email)
    }

    const onKeyDownEmailInput: IAuthModuleObject['onKeyDownEmailInput'] = key => {
        if (key === 'Enter') onSubmitCheckEmail()
    }

    const onChangePasswordInput: IAuthModuleObject['onChangePasswordInput'] = password => {
        setPasswordInputValue(password)
    }

    const onKeyDownPasswordInput: IAuthModuleObject['onKeyDownPasswordInput'] = key => {
        if (key === 'Enter' && isNewAccount === true) return onSubmitSignUp()
        if (key === 'Enter' && isEmailRegistered === true) return onSubmitGetInto()
    }

    const onSubmitCheckEmail: IAuthModuleObject['onSubmitCheckEmail'] = async () => {
        props.app.showLoader()
        const response = await checkEmail(emailInputValue)
        if (response) setIsEmailRegistered(true)
        else {
            setIsEmailRegistered(false)
            setIsNewAccount(true)
        }
        props.app.hideLoader()
    }

    const onSubmitGetInto: IAuthModuleObject['onSubmitGetInto'] = async () => {
        props.app.showLoader()
        setIsIncorrectPassword(false)
        const response = await authService.getToken(emailInputValue, passwordInputValue)
        if (response === '') setIsIncorrectPassword(true)
        else {
            props.cookie.createCookieToken(
                props.setup.getLoadedConfig().merchantCookieKey,
                props.setup.getLoadedConfig().cookieDomain,
                response
            )
            props.app.onRedirectTo(props.setup.getLoadedConfig().merchantUrl)
        }
        props.app.hideLoader()
    }

    const setSetup: IAuthModuleObject['setSetup'] = setupConfig => {
        authService.setSetup(setupConfig)
    }

    const checkEmail: IAuthModuleObject['checkEmail'] = async (email) => {
        props.app.showLoader()
        const response = await authService.checkEmail(email)
        props.app.hideLoader()
        if (response.data.isRegistered) setFullName(response.data.firstName)
        return response.data.isRegistered
    }

    const onSubmitSignUp: IAuthModuleObject['onSubmitSignUp'] = async () => {
        props.app.showLoader()
        const response = await authService.createAccount(emailInputValue, passwordInputValue)
        if (response) setIsCreatedAccount(true)
        props.app.hideLoader()
    }

    const onRecoveryPassword: IAuthModuleObject['onRecoveryPassword'] = async () => {
        props.app.showLoader()
        const response = await authService.passwordRecovery(emailInputValue)
        if (response.status === 'success') setIsRecoveredAccount(true)
        props.app.hideLoader()
    }

    const validateEmail = (email: string) => {
        if (/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email)) setIsEmailValid(true)
        else setIsEmailValid(false)
    }

    const isValidatePassword = passwordInputValue === '' ? false : true 

    return {
        emailInputValue,
        passwordInputValue,
        onKeyDownEmailInput,
        fullName,
        isEmailValid,
        isEmailRegistered,
        isIncorrectPassword,
        isNewAccount,
        isCreatedAccount,
        isRecoveredAccount,
        isValidatePassword,
        onChangeEmailInput,
        onChangePasswordInput,
        onKeyDownPasswordInput,
        onSubmitCheckEmail,
        onSubmitGetInto,
        onSubmitSignUp,
        setSetup,
        checkEmail,
        onGoBack,
        onRecoveryPassword,
    }
}