import react from 'react'
import { useHistory } from 'react-router';
import { Cell, Row, Margin, Image, Background, All, Width, Label, Input, Submit, SmallCenteredText, SmallLink, InputError, Title, ShowWhenDesktop, CenterText, ShowWhenMobile, SuccessMessage } from '../elements/all.elements';
import { BackgroundImage } from '../elements/extra.elements';
import { IStoreObject } from '../store';

export interface SignInPageProps {
  store: IStoreObject
}

export const AuthPage: react.FC<SignInPageProps> = (props) => {

  const history = useHistory()


  return (
    <Row>
      <Cell desktopWidth={30}>
        <Background color='ddd'>
          <All>
            <Row>
              <Cell desktopWidth={100}>
                <Margin x={30} y={2}>
                  <Image src="/assets/images/logo.png" />
                </Margin>
              </Cell>
            </Row>
            <Row>
              <Cell desktopWidth={100}>
                <Margin x={10} y={10}>
                  {props.store.auth.isEmailRegistered ? (
                    <Title>Hola nuevamente {props.store.auth.fullName}!</Title>
                  ) : null}

                  {props.store.auth.isNewAccount === false && props.store.auth.isEmailRegistered === false ? (
                    <Title>Ingresá a tu cuenta o registrate</Title>
                  ) : null}


                  {props.store.auth.isNewAccount ? (
                    <Title>Registrate en Siembro</Title>
                  ) : null}

                  <br /><br />

                  <Label>Email</Label>
                  <Input
                    disabled={props.store.auth.isEmailRegistered}
                    type='email'
                    value={props.store.auth.emailInputValue}
                    onChange={e => props.store.auth.onChangeEmailInput(e.target.value)}
                    onKeyDown={e => props.store.auth.onKeyDownEmailInput(e.key)}
                  />

                  {props.store.auth.isEmailRegistered === true ?(
                    <CenterText>
                      <SmallLink onClick={props.store.auth.onRecoveryPassword}>Olvidaste tu contraseña?</SmallLink>
                    </CenterText>
                  ): null}

                  <br/>

                  {props.store.auth.isEmailRegistered === true || props.store.auth.isNewAccount === true ? (
                    <>
                      <Label>Contraseña</Label>
                      <Input
                        type='password'
                        value={props.store.auth.passwordInputValue}
                        onChange={e => props.store.auth.onChangePasswordInput(e.target.value)}
                        onKeyDown={e => props.store.auth.onKeyDownPasswordInput(e.key)}
                      />
                      {props.store.auth.isIncorrectPassword ? (
                        <InputError>La contraseña no es correcta</InputError>
                      ) : null}
                    </>
                  ) : null}

                  <br />

                  {props.store.auth.isNewAccount === true ? (
                    <CenterText>
                      <SmallLink onClick={props.store.auth.onGoBack}>Registrarme con otra cuenta</SmallLink>
                    </CenterText>
                  ) : null}

                  {props.store.auth.isEmailRegistered === true ? (
                    <CenterText>
                      <SmallLink onClick={props.store.auth.onGoBack}>Ingresar con otra cuenta</SmallLink>
                    </CenterText>
                  ) : null}

                  {props.store.auth.isEmailRegistered === false && props.store.auth.isNewAccount !== false ? (
                    <SmallCenteredText>
                      Al ingresar o registrarte con cualquiera de los métodos anteriores estás aceptando nuestros términos y condiciones de uso y
                      <SmallLink href={props.store.setup.config.privacyPolicyUrl}> política de privacidad</SmallLink>.
                    </SmallCenteredText>
                  ) : null}

                  {props.store.auth.isEmailRegistered === false && props.store.auth.isNewAccount === false ? (
                    <Margin x={30} y={3}>
                      <Submit disabled={props.store.auth.isEmailValid === false} onClick={props.store.auth.onSubmitCheckEmail}>SIGUIENTE</Submit>
                    </Margin>
                  ) : null}

                  {props.store.auth.isNewAccount === true ? (
                    <Margin x={30} y={3}>
                      <Submit disabled={props.store.auth.isValidatePassword === false} onClick={props.store.auth.onSubmitSignUp}>REGISTRARME</Submit>
                    </Margin>
                  ) : null}

                  {props.store.auth.isEmailRegistered === true ? (
                    <Margin x={30} y={3}>
                      <Submit disabled={props.store.auth.isValidatePassword === false}onClick={props.store.auth.onSubmitGetInto}>INGRESAR</Submit>
                    </Margin>
                  ) : null}
                </Margin>


                {props.store.auth.isCreatedAccount ? (
                  <Margin x={10} y={0}>
                    <SuccessMessage>Te hemos enviado un correo con las indicaciones para poder activar tu cuenta.</SuccessMessage>
                  </Margin>
                ) : null}

                {props.store.auth.isRecoveredAccount ? (
                  <Margin x={10} y={0}>
                    <SuccessMessage>Te hemos enviado un email con las indicaciones para poder recuperar tu contraseña.</SuccessMessage>
                  </Margin>
                ) : null}
              </Cell>
            </Row>
          </All>
        </Background>
      </Cell>
      <Cell desktopWidth={70}>
        <ShowWhenDesktop>
          <BackgroundImage src="/assets/images/background.jpg" />
        </ShowWhenDesktop>
      </Cell>
    </Row>
  )
}