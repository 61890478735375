import { useState } from 'react'
import { ISetup } from '../models/setup.model'
import { useSetupService } from '../services/setup.service'
import { IAppModuleObject } from './app.module'

export interface ISetupModuleProps {
    app: IAppModuleObject
}
export interface ISetupModuleObject {
    config: ISetup
    loadConfig: () => Promise<ISetup>
    getLoadedConfig: () => ISetup
}
export type TUseSetupModule = (props: ISetupModuleProps) => ISetupModuleObject

export const useSetupModule: TUseSetupModule = (props) => {

    const [config, setConfig] = useState<ISetupModuleObject['config']>({
        apiBaseUrl: '',
        merchantUrl: '',
        merchantCookieKey: '',
        backofficeCookieKey: '',
        privacyPolicyUrl: '',
        cookieDomain: ''
    })

    const setupService = useSetupService({})

    const loadConfig: ISetupModuleObject['loadConfig'] = async () => {
        props.app.showLoader()
        const response = await setupService.getConfig()
        setConfig(response)     
        props.app.hideLoader()
        return response
    }

    const getLoadedConfig: ISetupModuleObject['getLoadedConfig'] = () => ({...config})

    return {
        config,
        loadConfig,
        getLoadedConfig
    }
}