import React from 'react'
import { ISetupModuleObject, useSetupModule } from './modules/setup.module'
import { IAuthModuleObject, useAuthModule } from './modules/auth.module'
import { IAppModuleObject, useAppModule } from './modules/app.module'
import { ICookieModuleObject, useCookieModule } from './modules/cookie.module'


export interface IStoreProps {}
export interface IStoreObject {
    setup: ISetupModuleObject
    app: IAppModuleObject
    auth: IAuthModuleObject
    cookie: ICookieModuleObject
}
export type TUseStore = (props: IStoreProps) => IStoreObject


export const useStore: TUseStore = () => {

    const app = useAppModule({})
    const cookie = useCookieModule({})
    const setup = useSetupModule({app})
    const auth = useAuthModule({app, cookie, setup})

    React.useEffect(()=>{
        onInit()
    }, [])

    const onInit = async () => {
        const setupResponse = await setup.loadConfig()
        auth.setSetup(setupResponse)
    }

    return { 
        setup,
        app,
        auth,
        cookie
    }
}