import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { timer } from '../utils/timer'


export const Margin = styled.div<{x: number, y: number}>`
    margin: ${props=> props.y}% ${props=> props.x}% ;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`


export const Width = styled.div<{value: number}>`
    width: ${props=> props.value}%;
    display: flex;
    flex-direction: column;
` 


export const Page = styled.div`


`


export const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;

`

export const Cell = styled.span<{desktopWidth: number}>`
    width: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media (min-width: 1024px) {
        width: ${props=>props.desktopWidth}%;
    }

`

export const All = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background: #eee;
    font-family: Arial;
    font-color: #444;
    box-sizing: border-box;
    z-index: 0;
`

export const Image = styled.img<{src: string}>`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const Background = styled.div<{color:string}>`
    background:#${props=> props.color};
`

export const Title = styled.h1`
    color: #212529;
    font-size: 18px;
    width: 100%;
    text-align: center;
    font-weight: 100;
`

export const Label = styled.label`
    width: 100%;
    font-size:15px;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 10px;
`

export const Input = styled.input`
    width: 100%;
    padding: 12px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #aaa;
    font-size:15px;
    &:focus {
        outline: none;
        box-shadow: 0px 0px 2px green;
    }
`

export const InputError = styled.a`
    color: #d9534f;
    font-size: 14px;
    padding-top: 5px;
`

export const Submit = styled.button<{disabled: boolean}>`
    border: none;
    background: #26a69a;
    color: white;
    padding: 10px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    cursor: pointer;

    &:disabled {
        background: #ccc;
        cursor: default;

    }
`

export const SmallCenteredText = styled.p`
    align-content: center;
    text-align: center;
    font-size: 12px;
`

export const SmallLink = styled.a<{href?: string}>`
    font-size: 12px;
    color: ${props=> props.href ? '#007bff' : '#15313c' };
    text-decoration: underline;
    cursor: pointer;
`

/////////////////

const BackDropLoaderShadow = styled.div<{isOpen: boolean}>`
    display: flex;
    opacity: ${props => props.isOpen ? '1' : '0'};
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0,0,0,0.3);
    height: 100vh;
    width: 100%;
    margin: 0px;
    top: 0;
    left: 0;
    z-index: 998;
    transition: opacity .2s ease-in;
`

export const LoaderSpinner = styled.div`
    
    border: 16px solid #ccc;
    border-radius: 50%;
    border-top: 16px solid grey;
    width: 60px;
    height: 60px;

    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    

    /* Safari */
    @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }

`

export const Loader = (props: {isOpen: boolean})=> {

    const [isRendered, setIsRendered] = useState<boolean>(false)
    const [isOpenAnimation, setIsOpenAnimation] = useState<boolean>(false)
 
    useEffect(()=>{
        if(props.isOpen !== isRendered) changeAnimation(props.isOpen)
    }, [props.isOpen])

    const changeAnimation = async (isOpen: boolean) => {
        if(props.isOpen){
            setIsRendered(true)
            await timer(50)
            setIsOpenAnimation(true)
        }else{
            setIsOpenAnimation(false)
            await timer(300)
            setIsRendered(false)
        }
    }

    return (
        <div>
            {isRendered ? (
                <BackDropLoaderShadow isOpen={isOpenAnimation}>
                    <LoaderSpinner/>
                </BackDropLoaderShadow>
            ) : null}
        </div>       
    )
}


/////////////////////

export const ShowWhenDesktop = styled.div`
    display: none;
    @media (min-width: 1024px) {
        display: block;
    }
`

export const ShowWhenMobile = styled.div`
    display: block;
    @media (min-width: 1024px) {
        display: none;

    }

`

export const CenterText = styled.div`
    text-align:center;
    width: 100%;
`

export const SuccessMessage = styled.div`
    background: #c8dde4;
    color: #212529;
    padding: 15px;
    width: 100%;
    margin: 0px;
    box-sizing: border-box;
`