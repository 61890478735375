import { useState } from 'react'
import axios from 'axios'
import { ISetup } from '../models/setup.model'

export interface IAuthServiceProps {}
export interface IAuthServiseObject {
    setSetup: (setup: ISetup) => void
    checkEmail: (email: string) => Promise<ICheckEmailResponse>
    getToken: (email: string, password: string) => Promise<string>
    createAccount: (email: string, password: string) => Promise<boolean>
    passwordRecovery: (email: string) => Promise<IPasswordRecoveryResponse>
}
export type TUseAuth = (props: IAuthServiceProps) => IAuthServiseObject


interface ICheckEmailResponse {
    data: {
        firstName: string
        isRegistered: boolean
    }
    status: "success"
}

interface IGetTokenResponse {
    token: string
}

interface IRegistrationResponse {
    status: 'success' | string,
    data: boolean
}

interface IPasswordRecoveryResponse {
    status: "success" | string
}

export const useAuthService: TUseAuth = (props) => {

    const [setupConfig, setSetupConfig] = useState<ISetup>({
        apiBaseUrl: '',
        merchantUrl: '',
        merchantCookieKey: '',
        backofficeCookieKey: '',
        privacyPolicyUrl: '',
        cookieDomain: ''
    })

    const setSetup: IAuthServiseObject['setSetup'] = (setupConfig) => {
        setSetupConfig(setupConfig)
    }

    const checkEmail: IAuthServiseObject['checkEmail'] = async (email) => {
        const response = await axios.post<ICheckEmailResponse>(`${setupConfig.apiBaseUrl}/auth/check-email`, {email})
        return response.data
    }

    const getToken: IAuthServiseObject['getToken'] = async (email, password) => {

        const response = await axios.post<IGetTokenResponse>(`${setupConfig.apiBaseUrl}/auth`, {
            password: password,
            username: email
        })
        .then(r=> r)
        .catch(e=>({data:{token: ''}}))

        return response.data.token
    }

    const createAccount: IAuthServiseObject['createAccount'] = async (email, password) => {
        const response = await axios.post<IRegistrationResponse>(`${setupConfig.apiBaseUrl}/auth/registration`, {email, password})
        return response.data.data
    }

    const passwordRecovery: IAuthServiseObject['passwordRecovery'] = async (email) => {
        const response = await axios.post<IPasswordRecoveryResponse>(`${setupConfig.apiBaseUrl}/auth/password-recovery`, {email})
        return response.data
    }
    
    return {
        setSetup,
        checkEmail,
        getToken,
        createAccount,
        passwordRecovery
    }
}